/* rtl:begin:ignore */
@import "tippy.js/dist/tippy.css";
@import "swiper/swiper-bundle.min.css";
@tailwind base;
/* rtl:end:ignore */

@tailwind components;

@page {
  margin: 0.1cm;
}

html.logged-out-page {
  /* Base font size */
  font-size: 18px;
}

.text-shadow-lg {
  text-shadow: 0 0 3px rgb(50, 54, 80, 0.13), 0 0 10px rgb(50, 54, 80, 0.25);
}
/* rtl:begin:ignore */
.force\:left-0 {
  left: 0;
}
.force\:right-0 {
  right: 0;
}
/* rtl:end:ignore */
#launcher {
  @apply print:hidden;
}
body {
  @apply print:bg-white !important;
}
.lessons-swiper .swiper-slide {
  width: 18rem !important;
}
.locked-background {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='120' height='120' viewBox='0 0 120 120'%3E%3Cpath fill='none' d='M0 0h120v120H0z'/%3E%3Cpath d='M59.33 47A10.24 10.24 0 0050 57.34V70a3 3 0 003 3h14a3 3 0 003-3V57a10 10 0 00-10.67-10zM60 69a3 3 0 113-3 3 3 0 01-3 3zm6-9H54v-3a6 6 0 1112 0z' fill='rgba(255,255,255,0.1)'/%3E%3C/svg%3E");
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.break-words {
  word-break: break-word;
}
/* rtl:begin:ignore */
.tippy-box {
  box-shadow: 0px 1px 10px rgba(25, 33, 87, 0.12);
  background-color: #fff;
  @apply p-4 text-base font-light rounded-2xl;
}

.tippy-box[data-placement^="top"] > .tippy-arrow::before {
  border-top-color: #fff;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow::before {
  border-bottom-color: #fff;
}
.tippy-box[data-placement^="left"] > .tippy-arrow::before {
  border-left-color: #fff;
}
.tippy-box[data-placement^="right"] > .tippy-arrow::before {
  border-right-color: #fff;
}
/* rtl:end:ignore */
.bg-pattern {
  background-image: url("/assets/img/bg-pattern.png");
}

.button-large {
  @apply py-3 text-lg p-9;
}
.bg-grid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgba(255, 255, 255 , 0.2)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
}

.nav-link-2 {
  @apply relative flex items-center justify-center rounded text-mulberry-550 h-9 w-9;
  @apply hover:text-mulberry-600;
  @apply focus:outline-none focus:ring-2 focus:ring-mulberry-500;
}

.checkout-root {
  @apply fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center w-screen h-screen p-4 pb-5 bg-opacity-50 bg-grape-500;
}

.column-center {
  @apply flex flex-col items-center justify-center;
}

.toast-bottom-center {
  bottom: 50px;
}

.nav-link-icon {
  @apply transform group-hover:scale-100 group-hover:opacity-100;
  @apply absolute top-0 left-0 w-full transition transform scale-50 opacity-0;
}

.row-center {
  @apply flex items-center justify-center;
}

.foot-note {
  @apply font-light;
}

.button-animate-shrink {
  @apply w-full max-w-xs mx-auto origin-left;
  @apply transform hover:scale-90 hover:scale-y-105;
}

.mulberry-button {
  @apply overflow-hidden transition bg-white shadow-mulberry rounded-xl;
  @apply transform active:shadow-none active:translate-y-1;
}

.button-mulberry {
  @apply bg-mulberry-500 text-mulberry-800;
  @apply focus:ring-2 focus:ring-mulberry-600;
  @apply active:shadow-none;
}

.button-mulberry:not([disabled]) {
  @apply hover:shadow-mulberry;
}

.button-icon {
  @apply hover:bg-mulberry-300;
  @apply rounded-lg;
  @apply focus:ring-2 focus:ring-mulberry-550 focus:outline-none;
}

.mango-button {
  @apply text-white transition ease-in bg-gradient-to-b from-pineapple-550 to-mango-500;
  @apply hover:shadow-mango;
  @apply focus:ring-2 focus:ring-mango-600;
  @apply transform active:shadow-none active:translate-y-1;
}

.plum-button {
  @apply text-white bg-plum-500;
  @apply hover:shadow-plum;
  @apply active:shadow-none;
}
.modal-input {
  @apply w-full px-4 py-2 text-lg font-light rounded-lg appearance-none bg-mulberry-500;
  @apply focus:outline-none focus:ring-2 focus:ring-razberry-500;
}

.mobile-nav-link {
  @apply flex items-center justify-start w-full space-x-2;
  @apply focus:bg-mulberry-100;
}

.page-root {
  @apply pb-10 antialiased bg-mulberry-500 text-grape-500;
}

.panel {
  @apply -mx-4 bg-mulberry-450 sm:rounded-xl sm:mx-0;
}

.dashed-bg {
  @apply rounded-xl;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23B8CBD4A3' stroke-width='4' stroke-dasharray='6%2c 12' stroke-dashoffset='65' stroke-linecap='square'/%3e%3c/svg%3e");
}

.dashed-circle {
  @apply rounded-full;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23B6C5CCFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.dashed-square-sm {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23B6C5CCFF' stroke-width='2' stroke-dasharray='3%2c 6' stroke-dashoffset='32' stroke-linecap='square'/%3e%3c/svg%3e");
}

.dashed-grape {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%23536280FF' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='7' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 14px;
}

.button-base {
  @apply relative transform flex items-center py-1.5 px-3 font-medium rounded-lg transition duration-150 ease-in ring-0 ring-inset;
  @apply focus:outline-none;
}

.button-base:hover:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 10px;
  width: 100%;
}

.button-base:not([disabled]) {
  @apply hover:-translate-y-2;
  @apply transform active:translate-y-0;
}
.button-base[disabled] {
  @apply opacity-50;
}

.container {
  @apply max-w-6xl px-4 mx-auto;
}

.tag {
  @apply px-3 mt-2 mr-2 text-white rounded-full bg-blueberry-500;
  @apply hover:bg-blueberry-550;
}

.panel-row {
  @apply p-7;
}

.headline {
  @apply text-2xl font-bold;
}

.xp-badge {
  @apply px-2 ml-5 text-lg font-semibold leading-tight text-white rounded-xl;
}

.panel-upper-stripe {
  @apply border-t border-mulberry-550;
}

.button-kiwi {
  @apply text-white bg-kiwi-500;
  @apply hover:shadow-kiwi;
  @apply focus:outline-none focus:ring-2 focus:ring-kiwi-550 focus:ring-inset;
  @apply active:shadow-none;
}

.regular-title {
  @apply text-lg lg:text-2xl;
}

.panel-bottom-stripe {
  @apply border-b border-mulberry-550;
}

.loading-placeholder {
  content: "";
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  color: transparent;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 800px 104px;
  position: relative;
  animation-duration: 2s;
  @apply select-none;
}

.loading-placeholder-darker {
  background: linear-gradient(to right, #fff 8%, #f0f0f0 18%, #fff 33%);
}

.playlist-swiper .swiper-slide {
  height: auto;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.glow-half {
  background-image: url("/assets/img/color/glow-half.png");
  @apply h-32 bg-center bg-no-repeat bg-cover w-96;
}
.bg-row-pattern {
  background-image: url("/assets/img/bg-row-pattern.png");
}

/* rtl:ignore */
[dir="rtl"] .rtl\:flip-x {
  @apply transform -rotate-180;
}

.iso-picker-wrapper {
  @apply ml-4;
}

.text-shadow-blueberry {
  text-shadow: 0 2px 2px #1158a8;
}

.hero__bg {
  height: calc(100% - theme("spacing.28"));
  background-size: contain;
}

.cut-mulberry-tr:after {
  content: "";
  position: absolute;
  top: -4px;

  right: -4px;
  border-top: 40px solid #e6edef;
  border-left: 40px solid transparent;
  width: 0;
}
.cut-white-tr:after {
  content: "";
  position: absolute;
  top: -4px;

  right: -4px;
  border-top: 40px solid white;
  border-left: 40px solid transparent;
  width: 0;
}

.fade-copy-clipboard {
  animation-duration: 1s;
  animation-name: fadeCopyToCliboard;
}

@keyframes fadeCopyToCliboard {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-25px);
    opacity: 0;
  }
}

.animate-heartbeat {
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@keyframes heartbeat {
  from {
    transform: scale(1);

    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}

@tailwind utilities;
