.pad-xs-vert {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.pad-sm-vert {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pad-md-vert {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pad-lg-vert {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pad-xs-horz {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.pad-sm-horz {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.pad-md-horz {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pad-lg-horz {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pad-sm-top {
  padding-top: 0.5rem !important;
}

.pad-md-top {
  padding-top: 1rem;
}

.pad-lg-top {
  padding-top: 1.5rem;
}

.pad-sm-bottom {
  padding-bottom: 0.5rem;
}

.pad-md-bottom {
  padding-bottom: 1rem !important;
}

.pad-lg-bottom {
  padding-bottom: 1.5rem;
}
.pad-xl-bottom {
  padding-bottom: 3rem;
}

.pad-xs {
  padding: 0.25rem 0.25rem;
}

.pad-sm {
  padding: 0.5rem 1rem;
}

.pad-md {
  padding: 1rem 1.5rem;
}

.pad-lg {
  padding: 1rem 2rem;
}

.pad-xl {
  padding: 2rem 3rem;
  @include for-phone-only {
    padding: 2rem 1.5rem;
  }
}

.pad-xl-horz {
  padding-left: 3rem;
  padding-right: 3rem;
  @include for-phone-only {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.pad-xs-left {
  padding-inline-start: 0.2rem;
}

.mar-xs {
  margin: 5px 5px;
}

.mar-sm {
  margin: 8px 8px;
}

.mar-md {
  margin: 1rem 1rem;
}

.mar-lg {
  margin: 1.5rem 1.5rem;
}

.mar-sm-vert {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mar-md-vert {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mar-lg-vert {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mar-sm-horz {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mar-xs-horz {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.mar-md-horz {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mar-lg-horz {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mar-no-top {
  margin-top: 0rem !important;
}

.mar-no-bottom {
  margin-bottom: 0rem !important;
}

.mar-no-left {
  margin-inline-start: 0rem !important;
}

.pad-no {
  padding: 0rem !important;
}
.pad-no-bottom {
  padding-bottom: 0rem !important;
}

.mar-no {
  margin: 0 !important;
}

.mar-sm-bottom {
  margin-bottom: 0.5rem;
}

.mar-md-top {
  margin-top: 1rem;
}

.mar-md-bottom {
  margin-bottom: 1rem;
}

.mar-lg-bottom {
  margin-bottom: 2rem;
}

.mar-lg-top {
  margin-top: 2rem;
}

.mar-sm-right {
  margin-inline-end: 0.5rem;
}

.mar-sm-top {
  margin-top: 0.5rem;
}

.mar-md-right {
  margin-inline-end: 1rem;
}

.mar-lg-right {
  margin-inline-end: 2rem;
}

.mar-sm-left {
  margin-inline-start: 0.5rem;
}

.mar-md-left {
  margin-inline-start: 1rem;
}

.mar-lg-left {
  margin-inline-start: 2rem;
}

.mar-xl-left {
  margin-inline-start: 3rem !important;
}

.contain-dynamic {
  padding: 0% 15%;
  @media (max-width: 900px) {
    padding: 0% 10%;
  }
  @media (max-width: 600px) {
    padding: 0% 8%;
  }
  @media (max-width: 450px) {
    padding: 0% 4%;
  }
}
