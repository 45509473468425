.side-scroll-list {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &:not(.skip-margin) {
    > :first-child {
      margin-inline-start: 2em !important;
    }
  }
  &::after {
    // forces browser to add space at end
    content: "...";
    color: transparent;
    display: block;
    width: 3em;
    height: 2em;
  }
}

.centered-grid {
  display: grid;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  padding: 0% 2%;
  @media (max-width: 400px) {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row-center {
  display: flex;
  align-items: center;
}

.flex-center-2d {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.flex-grid-left {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.flex-apart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-apart-vert {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //color: $separatorColor;
  font-weight: 500;
  font-size: 1.1em;
  padding: 0.5em 2em;
  &.wrap {
    @media (max-width: 600px) {
      flex-wrap: wrap;
      justify-content: center;
      div {
        text-align: center;
      }
    }
  }
}

.my-container {
  margin-bottom: 50px;
  padding: 0 5%;
  @include for-phone-only {
    padding: 0 3%;
  }
}

.machine-grid {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  width: 100%;
  box-sizing: border-box;
  &.centered {
    justify-content: center;
  }
}

.small-thumbs {
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

.large-thumbs {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.grid-4-3 {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  border-radius: 10px;
  grid-gap: 2px 1px;
  padding-bottom: 0.3em;
  overflow: hidden;

  @media (max-width: 800px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
