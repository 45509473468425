// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dashboard-primary: mat-palette($mat-indigo);
$dashboard-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$dashboard-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dashboard-theme: mat-light-theme(
  (
    color: (
      primary: $dashboard-primary,
      accent: $dashboard-accent,
      warn: $dashboard-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($dashboard-theme);

/* You can add global styles to this file, and also import other style files */
@import "src/utilities/colors.scss", "src/utilities/padding.scss", "src/utilities/layouts.scss",
  "./utilities/typography.scss", "~ngx-sharebuttons/themes/default/default-theme", "~ngx-toastr/toastr";

.rtl {
  direction: rtl;
}

html,
body {
  height: auto !important;
}

.sick-nav {
  background: none !important;
  box-shadow: none !important;
}
.rtl {
  .bottom-left {
    left: auto;
    right: 50px;
  }
  .bottom-right {
    left: 0;
    right: auto;
  }
  .top-left {
    right: 0;
    left: auto;
  }
  .top-right {
    right: 10px;
    left: auto;
  }
  .top-right-outer {
    top: 5px;
    left: auto;
    right: calc(100% + 5px);
  }
}

body {
  padding: 0px;
  margin: 0px;
}

.screen-width {
  width: 100vw;
}

.screen-height {
  height: 100vh;
}

.flex-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.text-light {
  color: $textLight;
}

a {
  color: $textLight;
  cursor: pointer;
  transition: all 0.1s;
  &:hover:not(.button-base):not(.link) {
    color: $orange;
  }
  &.orange {
    color: $orange;
    &:hover {
      color: darken($orange, 15%);
    }
  }
}

.select {
  background: white;
  font-size: 0.9em;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAYAAABWk2cPAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAhGVYSWZNTQAqAAAACAAFARIAAwAAAAEAAQAAARoABQAAAAEAAABKARsABQAAAAEAAABSASgAAwAAAAEAAgAAh2kABAAAAAEAAABaAAAAAAAAAEgAAAABAAAASAAAAAEAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHaADAAQAAAABAAAAHQAAAAAtQXtSAAAACXBIWXMAAAsTAAALEwEAmpwYAAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpMwidZAAAA8UlEQVRIDe2TywrCMBBF61tx40bwJwRd6MKP8F/9Hr/AnQ9UUHyc23SghaAtNbjJhZMhyWRuM6RJEhU7EDsQO+DpQMOz5lsqk/fioPIUa6mMWWWDMkWHWdUrsQO+m6jOHQYgnV3wj23/crraYnzAGDYwgT10Id9KGd5gBDtYg0yb8ITKkrG0AhWQ2SeUJ326jMv4Mqql0hSOINML6CMUNT+B9iXLd7MaYz87OyPmjWSoVs6z/V4WfxasoIwPIEPdPJghtVPpEUkL2MJSE2Qf5GYBRnsk9rvZYwtgVSxpRhaLuwFndtOAFrF07EDswL868AYRwimzgi43xQAAAABJRU5ErkJggg==");
  background-position: right center;
  background-repeat: no-repeat;
  appearance: none;
  //margin: 1em 0.3em;
  min-height: 40px;
  padding: 1em;
  padding-right: 2em;
  box-shadow: 0 0.3em $dark;
  border-radius: 10px;
  border: 2px solid transparent;
  &:focus {
    border: 2px solid $blue;
    outline: none;
  }

  &.dark {
    background-color: $medium;
  }
}

.form-container {
  margin: 0;
  min-height: 80%;
  padding: 0em 15%;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.display-none {
  display: none;
}

.border-sm {
  border-radius: 10px;
}

.maxw {
  max-width: 550px;
}

.maxww {
  max-width: 900px;
}

.hover-enlarge {
  transition: all 0.2s;
  &:hover {
    transform: scale(120%);
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
.button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  font-size: 1em;
  white-space: nowrap;
  color: $buttonTextColor;
  position: relative;
  border: none;
  border-radius: 10px;
  padding: 0.6em 1.2em;
  transition: 0.2s all;
  transform: translateY(-0.2em);
  cursor: pointer;
  .btn-img {
    width: 1.2em;
    height: 1.2em;
    fill: $buttonTextColor;
    display: inline-block;
  }
  &:hover {
    color: darken($buttonTextColor, 10%);
    .btn-img {
      fill: darken($buttonTextColor, 10%);
    }
  }
  &.gray {
    background-color: $medium;
    color: $buttonTextColor !important;
  }
  &.selected &:hover {
    background-color: $orange;
  }
  &:disabled {
    cursor: default;
    box-shadow: none !important;
    transform: none;
  }
  box-shadow: 0 0.4em $dark;
  &:focus {
    outline: none;
  }
  &.orange {
    background: $orange;
    color: white;
    box-shadow: 0 0.4em darken($orange, 15%);
    .btn-img {
      fill: white;
    }
    &:disabled {
      background-color: gray;
    }
  }
  &.gray {
    background: $medium;
  }
  &.selected {
    color: white;
    background: $buttonSelectedBackground;
    box-shadow: 0 0.2em darken($orange, 15%);
    transform: translateY(0em);
    &.orange {
      background: $orange;
      box-shadow: 0 0.2em darken($orange, 15%);
    }
  }

  &:active {
    box-shadow: 0 0.1em darken($dark, 15%);
    transform: translateY(0.1em);
    &.orange {
      box-shadow: 0 0.1em darken($orange, 15%);
    }
  }

  &.green {
    flex: 0 1 100px;
    .circle-green-bg {
      background-color: $dark;
      clip-path: circle(50%);
    }
    box-shadow: 0 0.3em $dark;
    background-color: $light;
    &.selected {
      background: white;
      color: $textDefault;
      box-shadow: 0 0.1em 0 0.2em $blue;
      .circle-green-bg {
        background-color: $blue;
      }
    }
  }

  &.yellow {
    flex-direction: column;
    align-items: center;
    flex: 0 1 100px;
    box-shadow: 0 0.3em $dark;
    background-color: $light;
    &.selected {
      background: white;
      color: $textDefault;
      box-shadow: 0 0.1em 0 0.2em $yellow;
    }
  }
  &.no-styling {
    background: none;
    border: none;
    box-shadow: none;
    transform: none;
    color: $textDefault;
    &:hover {
      background: transparentize($color: $textDefault, $amount: 0.9);
      color: $orange;
    }
  }
}

.button-md {
  padding: 0.9em 1.6em;
}

.button-lg {
  font-size: 1.2em;
  padding: 0.5em 1.5em;
  * {
    font-size: 1.2em;
    color: white;
  }
}

.pointer {
  cursor: pointer;
}

.separator {
  width: 100%;
  height: 1px;
  opacity: 0.8;
  background-color: $separatorColor;
  margin: 1em 0em;
  &.small {
    margin: 0.3em 0em;
  }
  &-lineonly {
    @extend .separator;
    margin: 0em 0em;
  }
  &.light {
    background-color: transparentize($color: $separatorColor, $amount: 0.5);
  }
}

.separator-vert {
  height: 100%;
  width: 1px;
  opacity: 0.8;
  background-color: $separatorColor;
  &.light {
    background-color: transparentize($color: $separatorColor, $amount: 0.5);
  }
}

.hide-on-small {
  @include for-phone-only {
    display: none !important;
  }
}

.hide-on-big {
  @include for-tablet-portrait-up {
    display: none !important;
  }
}

#hzImg {
  display: none !important;
}

.left-arrow {
  position: absolute;
  left: 1em;
  transition: 0.2s all;
  padding: 0.3em 0.4em;
  border-radius: 8px;
  &:hover {
    background: white;
    box-shadow: $shadow;
  }
  top: 35%;
  z-index: 10;
}

.right-arrow {
  @extend .left-arrow;
  left: auto;
  right: 1em;
}

.white {
  color: white;
}

.border-box {
  box-sizing: border-box;
}

.left {
  width: 100%;
  text-align: left;
}

.white-box {
  box-sizing: border-box;
  max-width: 800px;
  border-radius: 15px;
  background-color: white;
  overflow: hidden;
}

.center {
  text-align: center;
}

.icon {
  width: 3em;
  height: 3em;
  object-fit: contain;
  &-white {
    @extend .icon;
    fill: white;
  }
  &-gray {
    @extend .icon;
    fill: $darkest;
  }
}

.lg {
  width: 4em;
  height: 4em;
}

.xl {
  width: 6em;
  height: 6em;
}

.sm {
  width: 2em;
  height: 2em;
}

.xs {
  width: 1.5em;
  height: 1.5em;
}

.icon-dark {
  @extend .icon;
  fill: $textDefault;
}

.icon-gray {
  @extend .icon;
  fill: $textLight;
}

.polycoins-container {
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  margin-left: auto;
  margin-right: auto;
  min-width: 30px;
  position: relative;
  height: 2.5em;
  padding: 0em 1em 0em 3em;
  text-shadow: 2px 2px 4px #00000011;
  width: fit-content;
  border-radius: 100px;
  color: white;
  background-color: $yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/assets/img/white/polycoin.svg");
  background-repeat: no-repeat;
  background-position: 10;
  background-size: 2em;
  background-position-x: 0.5em;
  background-position-y: center;
  box-shadow: 0px 2px 0px 3px $darkYellow;
  font-weight: 700;
}

.team-count-container {
  @extend .polycoins-container;
  box-shadow: 0px 2px 0px 3px $darkest;
  background-color: $dark;
  background-image: url("/assets/img/color/team.svg");
}

.team-machines-container {
  @extend .polycoins-container;
  box-shadow: 0px 2px 0px 3px $darkest;
  background-color: $dark;
  background-image: url("/assets/img/color/engine.svg");
}

.search-box {
  font-size: 1em;
  background-color: white;
  background-image: url("assets/img/color/search.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1em);
  background-position-y: 45%;
  background-size: 2em;
  background-color: white;
  border-radius: 5px;
  box-shadow: $shadow;
  padding: 1em;
  padding-inline-end: 3em;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  ::placeholder {
    font-weight: 200;
  }
  img {
    float: right;
    width: 2em;
    height: 2em;
  }
  &:focus {
    background-color: white;
  }
}

.float-center {
  margin: 0 10px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}

.rtl .search-box {
  background-position-x: 1em;
}

.search-box-width {
  width: calc(50% + 10em);
  @include for-phone-only {
    width: 96%;
  }
}

.input,
.textarea {
  padding: 1em 1.5em;
  margin: 0.3em 0em;
  font-size: 1em;
  box-sizing: border-box;
  // max-width: 100%;
  background: $medium;
  border-radius: 10px;
  border: 2px solid transparent;
  &::placeholder {
    font-style: italic;
    font-weight: 200;
  }
  &:focus {
    background-color: white;
    border: 2px solid $blue;
    outline: none;
  }
}

.full-width {
  width: 100% !important;
  flex: 0 0 100%;
  box-sizing: border-box;
}

.three-quarters-width {
  width: 75%;
}

.full-height {
  height: 100% !important;
}

.header-thin {
  font-weight: 250;
  font-size: 1.6em;
  color: $textLight;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  &.wrap {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.rtl .nortl {
  flex-direction: row-reverse;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.flex-left {
  display: flex;
}

.header {
  font-size: 1.8em;
  font-weight: 400;
}

.header-lg {
  font-size: 2.5em;
  font-weight: 500;
}

.header-sm {
  font-size: 1.3em;
  font-weight: 500;
}

.text-orange {
  color: $orange;
}

.text-default {
  color: $textDefault;
}

.pad-no-top {
  padding-top: 0em !important;
}

/* Importing Bootstrap SCSS file. */

.back-button {
  box-shadow: none;
  display: flex;
  transform: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 14px;
  height: 60px;
  .back-icon {
    width: 30px;
    height: 30px;
    margin-inline-start: 14px;
    fill: $buttonTextColor;
  }
  background: none;
  i {
    color: $textLight;
    margin-inline-start: 16px;
  }
  border: 1px solid $textLight;
  &-light {
    @extend .back-button;
    background-color: $textLight;
    i {
      color: white !important;
    }
    div {
      fill: white;
    }
  }
  @media (max-width: 400px) {
    div {
      display: none;
    }
  }
}

.rtl .back-button .fa-arrow-left {
  transform: rotate(180deg);
}

.error {
  font-size: small;
  color: #e91e63;
  padding: 7px;
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: #ffe7ef;
  width: 100%;
  box-sizing: border-box;
  &.callout {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 0px;
      transform: translateY(-100%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;

      border-bottom: 10px solid #ffe7ef;
    }
  }
}

.shadowed {
  box-shadow: $shadow;
}

.fit-content {
  min-width: fit-content;
}

.nodecor {
  text-decoration: none;
}
.options {
  width: 1.5em;
  height: 1.5em;
  transform: rotate(90deg);
  fill: $textLight;
  position: absolute;
  top: 0.5em;
  right: 1em;
  cursor: pointer;
}

.rtl .options {
  right: auto;
  left: 1em;
}

.machine-width {
  @media (max-width: 400px) {
    width: calc(97vw - 5px);
  }

  @media (min-width: 401px) and (max-width: 799px) {
    width: calc(49vw - 10px);
  }

  @media (min-width: 800px) and (max-width: 900px) {
    width: calc(49vw - 60px);
  }
  @media (min-width: 901px) and (max-width: 1200px) {
    width: calc(32vw - 34px);
  }
  @media (min-width: 1201px) and (max-width: 1700px) {
    width: calc(24vw - 25px);
  }
  @media (min-width: 1701px) {
    width: calc(20vw - 36px);
  }
}

.gray-bg {
  background: $medium !important;
}

.lightgray-bg {
  background: $medium !important;
}

.darkgray-bg {
  background: $darker !important;
}

.white-bg {
  background-color: white;
}

.stat {
  background: white;
  box-shadow: $thinShadow;
  padding: 1em;
  margin: 0.7em;
  border-radius: 15px;
  text-align: center;
  transition: all 0.2s;
  &:hover {
    transform: translateY(-10px);
  }
}

.nowrap {
  white-space: nowrap;
}

.yeswrap {
  white-space: normal;
}

.gray-button-container {
  background: $medlight;
  height: 5em;
  width: 100%;
}

.band {
  padding: 3% 5%;
  @media (max-width: 400px) {
    padding: 2em 2%;
  }
  &.gray {
    background-color: $medlight;
  }
  &.pattern {
    // background-image: linear-gradient(to bottom right, #8ace4a, #3bb163),
    // background-image: url("../../../../assets/img/teacher_pattern.png");
    // background-size: 80px;
    background: linear-gradient(to bottom right, #8ace4a, #3bb163),
      url("./assets/img/teacher_pattern.png") 0% 0% padding-box;
    // background-color: #8ace4a;
    div {
      color: white;
    }
  }
  &.blue {
    background: linear-gradient(to bottom, #01bcd5, #269afb);
    div {
      color: white;
    }
  }
  &.nopad {
    padding-left: 0%;
    padding-right: 0%;
  }
  &.dark {
    background: linear-gradient(to bottom right, #323650, #607d8b);
  }
}

.fancy-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 971px) {
    flex-wrap: wrap;
  }
}

.teachers-description {
  font-size: 1em;
  padding: 1em;
  border-radius: 10px;
  background: transparentize($color: #0072d4, $amount: 0.7);
}

.flat-light {
  &:not(:hover) {
    color: white;
    box-shadow: none;
    transform: none;
    background: transparentize($color: white, $amount: 0.8);
  }
}

.flat-dark {
  &:not(:hover) {
    color: $textDefault;
    box-shadow: none;
    transform: none;
    background: transparentize($color: $medium, $amount: 0.3);
  }
}

.block-outer {
  width: 250px;
  height: 350px;
  flex: 0 0 250px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid $medium;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    height: 250px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: contain;
  }
  .title {
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
  }
  .description {
    font-size: 0.9em;
    text-align: center;
  }
}

.white-border {
  border: 2px solid white;
}

.pad-top-lots {
  padding-top: 6em;
}

.flex-bottom {
  display: flex;
  align-items: flex-end;
}

.flex-grow {
  flex-grow: 1;
}

.ltr {
  .no-right-radius {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .no-left-radius {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}

.rtl {
  .no-left-radius {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .no-right-radius {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}

.wrap-on-small {
  @media (max-width: 450px) {
    flex-wrap: wrap !important;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toast {
  background-color: $orange;
  * {
    color: white;
  }
}

.toast-success {
  background-color: $orange;
}

.toast-message {
  color: white !important;
}

.signup-back {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.enable-newlines {
  white-space: pre;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  color: $textDefault;
}


.partner-logo {
  width: 200px;
  margin: 15px;
  height: 50px;
  object-fit: contain;
  fill: gray;
  filter: grayscale(100%);
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    fill: none;
    filter: none;
  }
}

.transparent-veil {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  cursor: auto;
  background: transparent;
}

// overlay stuff

.elevate#onboarding-active {
}

.elevate {
  background: #e8eced;
  position: relative;
  @include mat-elevation-transition;
  @include mat-elevation(8);
  z-index: 10000;
}

.right-align {
  text-align: end;
}

.my-backdrop {
  background: rgba(0, 0, 0, 0.6);
}

.scrollable-band-machine-width {
  @media (max-width: 400px) {
    width: 80vw;
  }
  @media (min-width: 401px) and (max-width: 799px) {
    width: calc(45vw - 10px);
  }

  @media (min-width: 800px) and (max-width: 900px) {
    width: calc(45vw - 60px);
  }
  @media (min-width: 901px) and (max-width: 1200px) {
    width: calc(28vw - 34px);
  }
  @media (min-width: 1201px) and (max-width: 1700px) {
    width: calc(22vw - 25px);
  }
  @media (min-width: 1701px) {
    width: calc(18vw - 36px);
  }
}

.inline-block {
  display: inline-block;
}

// span {
//   white-space: pre;
// }

// body.modal-open {
//   // block scroll for mobile;
//   // causes underlying page to jump to top;
//   // prevents scrolling on all screens
//   overflow: hidden;
//   position: absolute;
// }

.cover {
  object-fit: cover;
}

.ltr {
  direction: ltr !important;
}

.body-rtl .lessonplan-bg {
  right: auto !important;
  left: 40px !important;
}

#launcher {
  @include for-phone-only {
    right: auto !important;
    left: 20px !important;
  }
}

.body-rtl #launcher {
  right: auto !important;
  left: 20px !important;

  @include for-phone-only {
    left: auto !important;
    right: 20px !important;
  }
}

.rtl .floating-action-button {
  right: auto !important;
  left: 2em !important;
}

.menu-button {
  position: absolute;
  top: 33px;
  left: 30px;
  @media (max-width: 1200px) {
    display: none;
  }
}

.rtl {
  .menu-button {
    left: auto;
    right: 30px;
  }
}

.transparent {
  opacity: 0;
}

.stat-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  .body {
    flex-grow: 1;
    padding: 1em 0.5em;
  }
  height: 80px;
  margin: 0.5em;
  border-radius: 20px;
  img {
    padding: 0.5em;
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
  .heading {
    font-size: 0.9em;
    font-weight: 300;
  }
  .number {
    font-size: 2em;
    font-weight: 400;
  }
  background-color: $light;
  p {
    font-size: 1em;
  }
}

.social-icon {
  font-size: 2.5em;
  color: $darker;
  transition: all 0.2s;
  :hover {
    color: $darkest;
  }
  margin: 4px;
}

.gray-outlined-bg {
  padding: 1rem;
  background: $medlight;
  border: 1px solid $dark;
  border-radius: 10px;
}

.square-wipe {
  transition: opacity 0.2s ease-out, top 0.4s, left 0.4s, width 0.4s, height 0.4s, border-radius 0.4s;
  z-index: 999999;
  position: fixed;
  border-radius: 10px;
  background-color: $textDefault;
}

.pill {
  font-size: 0.8rem;
  color: white !important;
  padding: 3px 5px;
  background: $orange;
  border-radius: 5px;
  :hover {
    background: lighten($color: $orange, $amount: 10%);
  }
}

.bg-default {
  background-color: $textDefault !important;
}

.tippy-content {
  color: $textDefault;
}

.max-full-width {
  max-width: 100%;
}

.image-button {
  background-color: #ecf1f3;
  width: 7rem;
  height: 7rem;
  cursor: pointer;
  transition: 0.16s all ease-in-out;
  &:hover {
    transform: scale(1.1);
    filter: brightness(102%);
  }
  padding: 0.6rem;
  border-radius: 0.4rem;
  margin: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.filter-grayscale {
  filter: grayscale(100%) brightness(120%);
}

.underline-links a {
  text-decoration: underline;
}

.my-table td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}
