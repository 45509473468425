$light: #f8f9f9;
$medlight: #f0f4f5;
$medium: #e8eced;
$darker: #dae1e3;
$dark: #c2ccd1;
$separatorColor: #b2c0c6;
$buttonTextColor: #97abb2;
$darkest: #688d9f;
$textLight: #607d8b;
$textDefault: #323650;
$orange: #ff5722;

$yellow: #ffc104;
$darkYellow: #eaa304;
$blue: #01bcd4;
$green: #8bc34a;
$red: #ff4611;

$buttonSelectedBackground: linear-gradient(to bottom right, #ff8939, #ff5a23);
$shadow: 0px 15px 30px #26474b12;
$thinShadow: 0px 0px 2px #14206f26;

@mixin for-phone-only {
  @media (max-width: 799px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 800px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
