@import "src/utilities/colors.scss";

h1 {
  font-size: 2em;
  font-weight: 500;
  padding: 1em 2em;
}

h2 {
  font-size: 1.5em;
  font-weight: 500;
  padding: 0.5em 1em;
}

.thin {
  font-weight: 300;
}

.small {
  font-size: smaller;
}

.larger {
  font-size: 1.2em !important;
}

.large {
  font-size: 3em !important;
}

.darkgray {
  color: $textLight;
}

.subtle {
  color: $textLight !important;
}

.text-white {
  color: white;
}

.text-default {
  color: $textDefault !important;
}
.text-green {
  color: $green;
}

.text-yellow {
  color: $yellow;
}

.text-red {
  color: $red;
}

.text-orange {
  color: $orange;
}

.thicc {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}
